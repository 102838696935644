import React from 'react'

export default {
  title: (
    <>
      What is the Pods <b>protocol</b>?
    </>
  ),
  description: (
    <>
      Pods enables the easiest way to hedge crypto assets in Ethereum. The Pods
      Protocol is a decentralized non-custodial Options Protocol that allows
      users to buy, sell and provide liquidity using the new Options AMM. Check
      below the step-by-step of a put option buyer (or seller).
    </>
  ),
  status: {
    label: <>Now viewing</>,
    buyer: "The buyer's perspective",
    seller: "The seller's perspective"
  }
}
