import React from 'react'

export default {
  headline: {
    title: 'The easiest way to hedge',
    assets: ['ETH', 'BTC', 'LINK', 'MKR', 'YFI']
  },
  description: (
    <>
      Crypto volatility keeps you from having a good night's sleep. Hedge crypto
      and protect your portfolio with Pods. Unlock a new level of safety for
      your assets.
    </>
  ),
  action: 'Start Hedging',
  news: {
    title: null,
    to: null
  }
}
