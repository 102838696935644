import React, { useEffect } from 'react'
import styled from 'styled-components'

import { data } from '../../constants'

import { Nav, Footer, Reminder } from '../../components/shared'
import {
  Header,
  Knowledge,
  Lifecycle,
  Why,
  Built,
  Investors
} from '../../components/specific/Landing'

const Wrapper = styled.div`
  width: 100%;
  background-color: ${props => props.theme.colors.white};
`

const Content = styled.div`
  position: relative;
  width: 100%;
  padding-top: calc(${props => props.theme.sizes.navHeight} - 1px);
`

function Landing () {
  useEffect(() => {
    window.scrollTo(0, 0)
    document.querySelector('body').scrollTo(0, 0)
  }, [])

  return (
    <Wrapper>
      <Nav />
      <Content>
        <Header data={data.header} />
        <Knowledge data={data.knowledge} />
        <Lifecycle data={data.lifecycle} />
        <Why data={data.why} />
        <Built data={data.built} />
        <Reminder data={data.reminder} />
        <Investors data={data.investors} />
      </Content>
      <Footer />
    </Wrapper>
  )
}

export default Landing
