import React from 'react'

import Theme from '@pods-finance/themes'
import globals from '@pods-finance/globals'
import { ExternalLink } from '@pods-finance/components'

import { rgba } from 'polished'

import IconDashboard from '@material-ui/icons/DashboardRounded'
import IconLimited from '@material-ui/icons/SecurityRounded'
import IconDecentralized from '@material-ui/icons/TrackChangesRounded'
import IconFinancial from '@material-ui/icons/OfflineBoltRounded'

export default {
  title: (
    <>
      <b>Why Pods?</b>
    </>
  ),
  list: [
    {
      Icon: IconDashboard,
      color: Theme.colors.primary,
      background: rgba(Theme.colors.primary, 0.05),
      title: 'Composable',
      description:
        "Pods' design leverages DeFi's composability and makes it easy for other projects to integrate with the Pods Protocol."
    },
    {
      Icon: IconLimited,
      color: Theme.colors.yellow2,
      background: rgba(Theme.colors.yellow2, 0.05),
      title: 'Options Specific AMM',
      description: (
        <>
          Tailor made to DeFi options, it unlocks a different experience of
          earning while using the liquidity provision feature in an options
          pool. More{' '}
          <b style={{ textDecoration: 'underline' }}>
            <ExternalLink to={globals.links.gitbook.AMM.link}>
              here
            </ExternalLink>
          </b>
          .
        </>
      )
    },
    {
      Icon: IconDecentralized,
      color: Theme.colors.purple,
      background: rgba(Theme.colors.purple, 0.05),
      title: 'Multiple Use Cases',
      description:
        'Sell, buy, provide liquidity for both puts and calls. It will result in many different ways to interact with the protocol. Find the one that suits you best.'
    },
    {
      Icon: IconFinancial,
      color: Theme.colors.green3,
      background: rgba(Theme.colors.green3, 0.05),
      title: 'Decentralized Protocol',
      description: (
        <>
          We’re in beta and currently hold admin keys to make the funds of our
          users safe. We expect to let go of the admin keys by the end of the
          year. More{' '}
          <b style={{ textDecoration: 'underline' }}>
            <ExternalLink to={globals.links.gitbook.adminKeys.link}>
              here
            </ExternalLink>
          </b>
          .
        </>
      )
    }
  ],
  twitter: [
    <>
      <blockquote className='twitter-tweet'>
        <p lang='en' dir='ltr'>
          Huge! From{' '}
          <a href='https://twitter.com/EthereumDenver?ref_src=twsrc%5Etfw'>
            @EthereumDenver
          </a>{' '}
          and{' '}
          <a href='https://twitter.com/ETHGlobal?ref_src=twsrc%5Etfw'>
            @ETHGlobal
          </a>{' '}
          to{' '}
          <a href='https://twitter.com/TheLAOOfficial?ref_src=twsrc%5Etfw'>
            @TheLAOOfficial
          </a>{' '}
          <span role='img' aria-label='love'>
            😍
          </span>{' '}
          Congrats{' '}
          <a href='https://twitter.com/RafaellaBaraldo?ref_src=twsrc%5Etfw'>
            @RafaellaBaraldo
          </a>{' '}
          &amp; team{' '}
          <span role='img' aria-label='applause'>
            👏
          </span>{' '}
          <a href='https://t.co/7p6RMo2UlV'>https://t.co/7p6RMo2UlV</a>
        </p>
        &mdash; stani.eth{' '}
        <span role='img' aria-label='ghost'>
          👻
        </span>{' '}
        (@StaniKulechov){' '}
        <a href='https://twitter.com/StaniKulechov/status/1288930226488201219?ref_src=twsrc%5Etfw'>
          July 30, 2020
        </a>
      </blockquote>
    </>,
    <>
      <blockquote className='twitter-tweet'>
        <p lang='en' dir='ltr'>
          <span role='img' aria-label='party'>
            🎉
          </span>{' '}
          welcome on board{' '}
          <a href='https://twitter.com/TheLAOOfficial?ref_src=twsrc%5Etfw'>
            @TheLAOOfficial
          </a>{' '}
          <a href='https://t.co/eTFI7PgnmC'>https://t.co/eTFI7PgnmC</a>
        </p>
        &mdash; Pods (@PodsFinance){' '}
        <a href='https://twitter.com/PodsFinance/status/1288913825572499456?ref_src=twsrc%5Etfw'>
          July 30, 2020
        </a>
      </blockquote>
    </>,
    <>
      <blockquote className='twitter-tweet'>
        <p lang='en' dir='ltr'>
          Huge! From{' '}
          <a href='https://twitter.com/EthereumDenver?ref_src=twsrc%5Etfw'>
            @EthereumDenver
          </a>{' '}
          and{' '}
          <a href='https://twitter.com/ETHGlobal?ref_src=twsrc%5Etfw'>
            @ETHGlobal
          </a>{' '}
          to{' '}
          <a href='https://twitter.com/TheLAOOfficial?ref_src=twsrc%5Etfw'>
            @TheLAOOfficial
          </a>{' '}
          <span role='img' aria-label='love'>
            😍
          </span>{' '}
          Congrats{' '}
          <a href='https://twitter.com/RafaellaBaraldo?ref_src=twsrc%5Etfw'>
            @RafaellaBaraldo
          </a>{' '}
          &amp; team{' '}
          <span role='img' aria-label='applause'>
            👏
          </span>{' '}
          <a href='https://t.co/7p6RMo2UlV'>https://t.co/7p6RMo2UlV</a>
        </p>
        &mdash; stani.eth{' '}
        <span role='img' aria-label='ghost'>
          👻
        </span>{' '}
        (@StaniKulechov){' '}
        <a href='https://twitter.com/StaniKulechov/status/1288930226488201219?ref_src=twsrc%5Etfw'>
          July 30, 2020
        </a>
      </blockquote>
    </>,
    <>
      <blockquote className='twitter-tweet'>
        <p lang='en' dir='ltr'>
          Bought my first options in the{' '}
          <a href='https://twitter.com/PodsFinance?ref_src=twsrc%5Etfw'>
            @PodsFinance
          </a>{' '}
          beta <a href='https://t.co/1eHuyAVuU5'>pic.twitter.com/1eHuyAVuU5</a>
        </p>
        &mdash; Joseph
        <span role='img' aria-label='point'>
          👉👈
        </span>
        Delong (@josephdelong){' '}
        <a href='https://twitter.com/josephdelong/status/1282734677581795328?ref_src=twsrc%5Etfw'>
          July 13, 2020
        </a>
      </blockquote>{' '}
    </>
  ]
}
