import React from 'react'
import styled from 'styled-components'
import { ReactComponent as Dots } from '../../../../../assets/decorator/dots.svg'
import { ReactComponent as DecoratorOvalSmall } from '../../../../../assets/decorator/oval_small.svg'
import { Layer as LayerPartial } from '../../../../atoms'

const Layer = styled(LayerPartial)`
  &[data-name='decorator'] {
    & > svg {
      height: 300px;
      width: 300px;
      position: absolute;
      top: -280px;
      right: -100px;
    }
  }
`

const DotsLeft = styled.div`
  height: 490px;
  width: 490px;
  position: absolute;
  margin-top: -260px;
  left: -440px;
  opacity: 1;
  & > svg {
    height: 100%;
    width: 100%;
    object-fit: contain;
    fill: ${props => props.theme.colors.white};
  }
  ${props => props.theme.medias.small} {
    display: none;
  }
`

const DotsRight = styled.div`
  height: 490px;
  width: 490px;
  margin-left: auto;
  margin-top: -480px;
  margin-right: -190px;
  opacity: 1;
  & > svg {
    height: 100%;
    width: 100%;
    object-fit: contain;
    fill: ${props => props.theme.colors.white};
  }
`

function Underlay () {
  return (
    <>
      <Layer elevation={4} data-name='decorator'>
        <DecoratorOvalSmall />
      </Layer>
      <Layer elevation={3}>
        <DotsLeft>
          <Dots />
        </DotsLeft>
      </Layer>
      <Layer elevation={2}>
        <DotsRight>
          <Dots />
        </DotsRight>
      </Layer>
    </>
  )
}

export default Underlay
